import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
@Component({
  selector: 'app-recaptcha-disclaimer',
  template: `<div
    [ngClass]="'recaptchaDisclaimer__light'"
    [innerHtml]="'recaptcha-disclaimer' | translate"
  ></div>`,
  imports: [TranslateModule, CommonModule],
})
export class RecaptchaDisclaimerComponent {}
